import React, { type FormEventHandler, type RefObject } from "react";

type AddFormProps = {
  fRef: RefObject<HTMLFormElement>;
  iTRef?: RefObject<HTMLInputElement>;
  iHRef?: RefObject<HTMLInputElement>;
  iORef?: RefObject<HTMLInputElement>;
  onSubmit: FormEventHandler;
};
const AddPageForm = ({ fRef, iTRef, iHRef, iORef, onSubmit }: AddFormProps) => (
  <div style={{ borderBottom: "1px solid white" }}>
    <form
      className=""
      ref={fRef}
      autoComplete="off"
      onSubmit={onSubmit}
      name="add"
    >
      <fieldset className="formfield">
        <label htmlFor="title">Title: </label>
        <input
          id="title"
          ref={iTRef}
          type="text"
          name="title"
          placeholder="Oneword"
          required
        />
      </fieldset>
      <fieldset className="formfield">
        <label htmlFor="addheading">heading: </label>
        <input
          id="addheading"
          ref={iHRef}
          type="text"
          name="heading"
          required
        />
      </fieldset>
      <fieldset className="formfield">
        <label htmlFor="addorder">order: </label>
        <input id="addorder" ref={iORef} type="text" name="order" required />
      </fieldset>

      <button type="submit" className="btn valuebtn">
        Add Page
      </button>
    </form>
  </div>
);

export default AddPageForm;
