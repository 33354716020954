import { useRef, useState, useEffect } from "react";
import EditForm from "../template/EditForm";
import AddPublicationsForm from "./add/AddPublicationsForm";
import { db } from "../../fbserve";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  orderBy,
  query,
} from "firebase/firestore";

const BioPublicationsForm = () => {
  let dataRef: any;
  const pubStr = "pages/biography/publications";
  const publicationsRef = query(collection(db, pubStr));
  const qpublications = query(publicationsRef, orderBy("order", "desc"));
  // add Technique Steps Refs
  const pubCategoryFormRef = useRef<HTMLFormElement>(null);
  const pubCategoryRef = useRef<HTMLInputElement>(null);
  const pubDatesFormRef = useRef<HTMLFormElement>(null);
  const pubDatesRef = useRef<HTMLInputElement>(null);
  const pubLocationFormRef = useRef<HTMLFormElement>(null);
  const pubLocationRef = useRef<HTMLInputElement>(null);
  const pubOrderFormRef = useRef<HTMLFormElement>(null);
  const pubOrderRef = useRef<HTMLInputElement>(null);
  const pubSectionFormRef = useRef<HTMLFormElement>(null);
  const pubSectionRef = useRef<HTMLInputElement>(null);
  const pubSubtextFormRef = useRef<HTMLFormElement>(null);
  const pubSubtextRef = useRef<HTMLInputElement>(null);
  const pubTextFormRef = useRef<HTMLFormElement>(null);
  const pubTextRef = useRef<HTMLTextAreaElement>(null);
  const pubYearFormRef = useRef<HTMLFormElement>(null);
  const pubYearRef = useRef<HTMLInputElement>(null);
  const addPubFormRef = useRef<HTMLFormElement>(null);
  const addPubOrderRef = useRef<HTMLInputElement>(null);
  const addPubTextRef = useRef<HTMLTextAreaElement>(null);

  const [publications, setSteps] = useState([] as Array<any> | null);
  const [editPub, setEditPub] = useState(false);
  const [bioPub, setBioPub] = useState(false);
  const [pubIndex, setPubIndex] = useState(0);
  const [redEdited, setRedEdited] = useState(true);
  const [deleted, setDeleted] = useState(false);

  const getPublications = async () => {
    const pubSteps = [] as Array<any>;
    const querySnapshot = await getDocs(qpublications);
    querySnapshot.forEach((doc: any) => {
      pubSteps?.push({ ...doc.data(), id: doc.id });
    }) as unknown as any[];
    setSteps(pubSteps);
  };

  useEffect(() => {
    getPublications();
  });

  useEffect(() => {
    setRedEdited(true);
  }, [redEdited]);

  const procEditPub = (i: number) => {
    const nextState = i;
    setEditPub(true);
    setPubIndex(nextState);
  };

  const addTechStep = async (ev: any) => {
    ev.preventDefault();

    const iOrder = addPubOrderRef.current?.value as string;
    await addDoc(collection(db, pubStr), {
      category: "bio",
      order: parseInt(iOrder),
      section: "Publications",
      subtext: "",
      text: addPubTextRef.current?.value,
    })
      .then(() => {
        addPubFormRef.current!.reset();
        getPublications();
      })
      .catch((error) => console.log(error.message));
  };

  const delPageSec = async (ev: any, id: string, deldir: string) => {
    ev.preventDefault();
    const confirmDel = window.confirm(
      `This cannot be undone: Are You Sure You Want to Delete this ${publications?.[pubIndex]?.text} Entry?`
    );
    if (confirmDel) {
      setDeleted(true);
      setRedEdited(false);
      await deleteDoc(doc(db, deldir, `${id}`));
      const setTime = setTimeout(() => {
        setDeleted(false);
        setEditPub(false);
        getPublications();
      }, 3300);
      return () => {
        clearTimeout(setTime);
      };
    }
  };

  const runAwardEdit = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.value;
    const inptName = refInpt.current?.name;
    const inptForm = refForm.current;
    if (!inptVal) {
      alert("Did You Forget To Insert A Value?");
      return;
    }
    if (inptName === "order") {
      inptVal = parseInt(inptVal);
    }
    if (inptName.includes("formats")) {
      const opts = inptForm.children[1].selectedOptions!;
      const catOptions = [];
      for (const option of opts) {
        if (option.selected) {
          catOptions.push(option.value);
        }
      }
      inptVal = catOptions;
    }
    dataRef = doc(db, dataStr, id);
    await updateDoc(dataRef, { [`${inptName}`]: inptVal });
    setRedEdited(true);
    inptForm.className = `${redEdited ? "editform red" : "editform"}`;
    inptForm.reset();
    getPublications();
  };

  return (
    <div>
      {bioPub === true ? (
        <div className="editdiv">
          {editPub ? (
            <div>
              <h4 style={{ marginTop: "0.5rem", marginBottom: "0" }}>
                {deleted ? (
                  <span style={{ color: "red" }}>Deleted!</span>
                ) : (
                  "Editing:"
                )}{" "}
                Biography Publications - {publications?.[pubIndex]?.text}
              </h4>
              <div>
                <EditForm
                  fRef={pubCategoryFormRef}
                  iRef={pubCategoryRef}
                  fname="category"
                  ftype="text"
                  detfull=""
                  aIndexName={publications?.[pubIndex]?.category}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      publications?.[pubIndex]?.id,
                      pubCategoryFormRef,
                      pubCategoryRef,
                      pubStr
                    )
                  }
                />
                <EditForm
                  fRef={pubDatesFormRef}
                  iRef={pubDatesRef}
                  fname="dates"
                  ftype="text"
                  detfull=""
                  aIndexName={publications?.[pubIndex]?.dates}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      publications?.[pubIndex]?.id,
                      pubDatesFormRef,
                      pubDatesRef,
                      pubStr
                    )
                  }
                />
                <EditForm
                  fRef={pubLocationFormRef}
                  iRef={pubLocationRef}
                  fname="location"
                  ftype="text"
                  detfull=""
                  aIndexName={publications?.[pubIndex]?.location}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      publications?.[pubIndex]?.id,
                      pubLocationFormRef,
                      pubLocationRef,
                      pubStr
                    )
                  }
                />
                <EditForm
                  fRef={pubOrderFormRef}
                  iRef={pubOrderRef}
                  fname="order"
                  ftype="text"
                  detfull=""
                  aIndexName={publications?.[pubIndex]?.order}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      publications?.[pubIndex]?.id,
                      pubOrderFormRef,
                      pubOrderRef,
                      pubStr
                    )
                  }
                />
                <EditForm
                  fRef={pubSectionFormRef}
                  iRef={pubSectionRef}
                  fname="section"
                  ftype="text"
                  detfull=""
                  aIndexName={publications?.[pubIndex]?.section}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      publications?.[pubIndex]?.id,
                      pubSectionFormRef,
                      pubSectionRef,
                      pubStr
                    )
                  }
                />
                <EditForm
                  fRef={pubSubtextFormRef}
                  iRef={pubSubtextRef}
                  fname="subtext"
                  ftype="text"
                  detfull=""
                  aIndexName={publications?.[pubIndex]?.subtext}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      publications?.[pubIndex]?.id,
                      pubSubtextFormRef,
                      pubSubtextRef,
                      pubStr
                    )
                  }
                />
                <EditForm
                  fRef={pubTextFormRef}
                  tRef={pubTextRef}
                  fname="text"
                  ftype="text"
                  detfull=""
                  aIndexName={publications?.[pubIndex]?.text}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      publications?.[pubIndex]?.id,
                      pubTextFormRef,
                      pubTextRef,
                      pubStr
                    )
                  }
                />
                <EditForm
                  fRef={pubYearFormRef}
                  iRef={pubYearRef}
                  fname="year"
                  ftype="text"
                  detfull=""
                  aIndexName={publications?.[pubIndex]?.year}
                  onSubmit={(ev) =>
                    runAwardEdit(
                      ev,
                      publications?.[pubIndex]?.id,
                      pubYearFormRef,
                      pubYearRef,
                      pubStr
                    )
                  }
                />
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <p>ID: {publications?.[pubIndex]?.id}</p>

                  <button
                    className="btn"
                    onClick={(ev) =>
                      delPageSec(ev, publications?.[pubIndex]?.id, pubStr)
                    }
                  >
                    Delete
                  </button>
                </div>
                <button className="btn" onClick={() => setEditPub(false)}>
                  Close
                </button>
              </div>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              paddingBottom: "1rem",
              width: "100%",
            }}
          >
            <div style={{ width: "100%" }}>
              <h4>Publications:</h4>
            </div>
            {publications?.map((pub: any, i: number) => (
              <button
                key={pub.order}
                style={{ margin: "0.36rem" }}
                className="btn"
                onClick={() => {
                  procEditPub(i);
                  setRedEdited((prev) => !prev);
                }}
              >
                {pub.text} {pub.order}
              </button>
            ))}
          </div>
          <h4>Add Publications:</h4>
          <div>
            <AddPublicationsForm
              onSubmit={(ev) => {
                addTechStep(ev);
              }}
              fRef={addPubFormRef}
              iTRef={addPubTextRef}
              iORef={addPubOrderRef}
            />
          </div>
          <button className="btn" onClick={() => setBioPub(false)}>
            Close Publications
          </button>
        </div>
      ) : (
        <button className="btn" onClick={() => setBioPub(true)}>
          Edit Publications
        </button>
      )}
    </div>
  );
};

export default BioPublicationsForm;
