import React, { type FormEventHandler, type RefObject } from "react";

type AddFormProps = {
  fRef: RefObject<HTMLFormElement>;
  iTRef?: RefObject<HTMLInputElement>;
  iSRef?: RefObject<HTMLInputElement>;
  iORef?: RefObject<HTMLInputElement>;
  onSubmit: FormEventHandler;
};
const AddStepsForm = ({
  fRef,
  iTRef,
  iSRef,
  iORef,
  onSubmit,
}: AddFormProps) => (
  <>
    <h4>Add Steps:</h4>
    <form
      className=""
      ref={fRef}
      autoComplete="off"
      onSubmit={onSubmit}
      name="add"
    >
      <fieldset className="formfield">
        <label htmlFor="title">Title: </label>
        <input
          id="title"
          ref={iTRef}
          type="text"
          name="title"
          placeholder="Artwork Title"
          required
        />
      </fieldset>
      <fieldset className="formfield">
        <label htmlFor="addkeyid">step: </label>
        <input
          id="addkeyid"
          ref={iSRef}
          type="text"
          name="step"
          placeholder="Description Title"
          required
        />
      </fieldset>
      <fieldset className="formfield">
        <label htmlFor="addorder">order: </label>
        <input id="addorder" ref={iORef} type="text" name="order" required />
      </fieldset>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginBottom: "1rem",
        }}
      >
        <button type="submit" className="btn valuebtn">
          Add Step
        </button>
      </div>
    </form>
  </>
);

export default AddStepsForm;
