import { useRef, useState, useEffect } from "react";
import EditForm from "../template/EditForm";
import { getDoc, updateDoc, doc } from "firebase/firestore";
import { db } from "../../fbserve";

const BioEduForm = () => {
  let dataRef: any;
  const eduStr = "pages/biography/edu";
  const eduRef = doc(db, eduStr, "education");

  const eduCategoryFormRef = useRef<HTMLFormElement>(null);
  const eduCategoryRef = useRef<HTMLInputElement>(null);
  const eduDatesFormRef = useRef<HTMLFormElement>(null);
  const eduDatesRef = useRef<HTMLInputElement>(null);
  const eduLocationFormRef = useRef<HTMLFormElement>(null);
  const eduLocationRef = useRef<HTMLInputElement>(null);
  const eduOrderFormRef = useRef<HTMLFormElement>(null);
  const eduOrderRef = useRef<HTMLInputElement>(null);
  const eduSectionFormRef = useRef<HTMLFormElement>(null);
  const eduSectionRef = useRef<HTMLInputElement>(null);
  const eduSubtextFormRef = useRef<HTMLFormElement>(null);
  const eduSubtextRef = useRef<HTMLInputElement>(null);
  const eduTextFormRef = useRef<HTMLFormElement>(null);
  const eduTextRef = useRef<HTMLTextAreaElement>(null);
  const eduYearFormRef = useRef<HTMLFormElement>(null);
  const eduYearRef = useRef<HTMLInputElement>(null);

  const [edu, setEdu] = useState<any>([]);
  const [bioEdu, setBioEdu] = useState(false);
  const [redEdited, setRedEdited] = useState(true);

  const getEdu = async () => {
    const eduSnapshot = await getDoc(eduRef);
    if (eduSnapshot.exists()) {
      setEdu({ ...eduSnapshot.data(), id: eduSnapshot.id });
    } else {
      // eduSnapshot.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  useEffect(() => {
    getEdu();
  });

  useEffect(() => {
    setRedEdited(true);
  }, [redEdited]);

  const runEduEdit = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.value;
    const inptName = refInpt.current?.name;
    const inptForm = refForm.current;
    if (!inptVal) {
      alert("Did You Forget To Insert A Value?");
      return;
    }
    if (inptName === "order") {
      inptVal = parseInt(inptVal);
    }
    if (inptName.includes("formats")) {
      const opts = inptForm.children[1].selectedOptions!;
      const catOptions = [];
      for (const option of opts) {
        if (option.selected) {
          catOptions.push(option.value);
        }
      }
      inptVal = catOptions;
    }
    dataRef = doc(db, dataStr, id);
    await updateDoc(dataRef, { [`${inptName}`]: inptVal });
    setRedEdited(true);
    inptForm.className = `${redEdited ? "editform red" : "editform"}`;
    inptForm.reset();
    getEdu();
  };

  return (
    <div>
      {bioEdu === true ? (
        <div className="editdiv">
          <div>
            <h4 style={{ marginTop: "0.5rem", marginBottom: "0" }}>
              Editing: Biography Born - {edu?.text}
            </h4>
            <div>
              <EditForm
                fRef={eduCategoryFormRef}
                iRef={eduCategoryRef}
                fname="category"
                ftype="text"
                detfull=""
                aIndexName={edu?.category}
                onSubmit={(ev) =>
                  runEduEdit(
                    ev,
                    edu?.id,
                    eduCategoryFormRef,
                    eduCategoryRef,
                    eduStr
                  )
                }
              />
              <EditForm
                fRef={eduDatesFormRef}
                iRef={eduDatesRef}
                fname="dates"
                ftype="text"
                detfull=""
                aIndexName={edu?.dates}
                onSubmit={(ev) =>
                  runEduEdit(ev, edu?.id, eduDatesFormRef, eduDatesRef, eduStr)
                }
              />
              <EditForm
                fRef={eduLocationFormRef}
                iRef={eduLocationRef}
                fname="location"
                ftype="text"
                detfull=""
                aIndexName={edu?.location}
                onSubmit={(ev) =>
                  runEduEdit(
                    ev,
                    edu?.id,
                    eduLocationFormRef,
                    eduLocationRef,
                    eduStr
                  )
                }
              />
              <EditForm
                fRef={eduOrderFormRef}
                iRef={eduOrderRef}
                fname="order"
                ftype="text"
                detfull=""
                aIndexName={edu?.order}
                onSubmit={(ev) =>
                  runEduEdit(ev, edu?.id, eduOrderFormRef, eduOrderRef, eduStr)
                }
              />
              <EditForm
                fRef={eduSectionFormRef}
                iRef={eduSectionRef}
                fname="section"
                ftype="text"
                detfull=""
                aIndexName={edu?.section}
                onSubmit={(ev) =>
                  runEduEdit(
                    ev,
                    edu?.id,
                    eduSectionFormRef,
                    eduSectionRef,
                    eduStr
                  )
                }
              />
              <EditForm
                fRef={eduSubtextFormRef}
                iRef={eduSubtextRef}
                fname="subtext"
                ftype="text"
                detfull=""
                aIndexName={edu?.subtext}
                onSubmit={(ev) =>
                  runEduEdit(
                    ev,
                    edu?.id,
                    eduSubtextFormRef,
                    eduSubtextRef,
                    eduStr
                  )
                }
              />
              <EditForm
                fRef={eduTextFormRef}
                tRef={eduTextRef}
                fname="text"
                ftype="text"
                detfull=""
                aIndexName={edu?.text}
                onSubmit={(ev) =>
                  runEduEdit(ev, edu?.id, eduTextFormRef, eduTextRef, eduStr)
                }
              />
              <EditForm
                fRef={eduYearFormRef}
                iRef={eduYearRef}
                fname="year"
                ftype="text"
                detfull=""
                aIndexName={edu?.year}
                onSubmit={(ev) =>
                  runEduEdit(ev, edu?.id, eduYearFormRef, eduYearRef, eduStr)
                }
              />
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <p>ID: {edu?.id}</p>
              </div>
              <button className="btn" onClick={() => setBioEdu(false)}>
                Close Education
              </button>
            </div>
          </div>
        </div>
      ) : (
        <button className="btn" onClick={() => setBioEdu(true)}>
          Edit Education
        </button>
      )}
    </div>
  );
};

export default BioEduForm;
