import React, { type FormEventHandler, type RefObject } from "react";

type AddFormProps = {
  fRef?: RefObject<HTMLFormElement>;
  iTRef?: RefObject<HTMLTextAreaElement>;
  iORef?: RefObject<HTMLInputElement>;
  onSubmit: FormEventHandler;
};
const AddTeachForm = ({ fRef, iTRef, iORef, onSubmit }: AddFormProps) => {
  return (
    <form
      className=""
      ref={fRef}
      autoComplete="off"
      onSubmit={onSubmit}
      name="add"
    >
      <fieldset className="formfield">
        <label htmlFor="teachtext">text: </label>
        <textarea
          style={{ marginLeft: "0.5rem" }}
          id="teachtext"
          ref={iTRef}
          name="text"
          rows={5}
          required
        />
      </fieldset>
      <fieldset className="formfield">
        <label htmlFor="addteachorder">order: </label>
        <input
          id="addteachorder"
          ref={iORef}
          type="text"
          name="order"
          required
        />
      </fieldset>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          marginBottom: "1rem",
        }}
      >
        <button type="submit" className="btn valuebtn">
          Add Teaching
        </button>
      </div>
    </form>
  );
};

export default AddTeachForm;
