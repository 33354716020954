import { useRef, useState, useEffect } from "react";
import EditForm from "../template/EditForm";
import { getDoc, updateDoc, doc } from "firebase/firestore";
import { db } from "../../fbserve";

const BioBornForm = () => {
  let dataRef: any;
  const bornStr = "pages/biography/bio";
  const bornRef = doc(db, bornStr, "born");

  const bornCategoryFormRef = useRef<HTMLFormElement>(null);
  const bornCategoryRef = useRef<HTMLInputElement>(null);
  const bornDatesFormRef = useRef<HTMLFormElement>(null);
  const bornDatesRef = useRef<HTMLInputElement>(null);
  const bornLocationFormRef = useRef<HTMLFormElement>(null);
  const bornLocationRef = useRef<HTMLInputElement>(null);
  const bornOrderFormRef = useRef<HTMLFormElement>(null);
  const bornOrderRef = useRef<HTMLInputElement>(null);
  const bornSectionFormRef = useRef<HTMLFormElement>(null);
  const bornSectionRef = useRef<HTMLInputElement>(null);
  const bornSubtextFormRef = useRef<HTMLFormElement>(null);
  const bornSubtextRef = useRef<HTMLInputElement>(null);
  const bornTextFormRef = useRef<HTMLFormElement>(null);
  const bornTextRef = useRef<HTMLTextAreaElement>(null);
  const bornYearFormRef = useRef<HTMLFormElement>(null);
  const bornYearRef = useRef<HTMLInputElement>(null);

  const [born, setBorn] = useState<any>([]);
  const [bioborn, setBioborn] = useState(false);
  const [redEdited, setRedEdited] = useState(true);

  const getBorn = async () => {
    const bornSnapshot = await getDoc(bornRef);
    if (bornSnapshot.exists()) {
      setBorn({ ...bornSnapshot?.data(), id: bornSnapshot?.id });
    } else {
      // bornSnapshot.data() will be undefined in this case
      console.log("No such document!");
    }
  };

  useEffect(() => {
    getBorn();
  });

  useEffect(() => {
    setRedEdited(true);
  }, [redEdited]);

  const runBornEdit = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.value;
    const inptName = refInpt.current?.name;
    const inptForm = refForm.current;
    if (!inptVal) {
      alert("Did You Forget To Insert A Value?");
      return;
    }
    if (inptName === "order") {
      inptVal = parseInt(inptVal);
    }
    if (inptName.includes("formats")) {
      const opts = inptForm.children[1].selectedOptions!;
      const catOptions = [];
      for (const option of opts) {
        if (option.selected) {
          catOptions.push(option.value);
        }
      }
      inptVal = catOptions;
    }
    dataRef = doc(db, dataStr, id);
    await updateDoc(dataRef, { [`${inptName}`]: inptVal });
    setRedEdited(true);
    inptForm.className = `${redEdited ? "editform red" : "editform"}`;
    inptForm.reset();
    getBorn();
  };

  return (
    <div>
      {bioborn === true ? (
        <div className="editdiv">
          <div>
            <h4 style={{ marginTop: "0.5rem", marginBottom: "0" }}>
              Editing: Biography Born - {born?.text}
            </h4>
            <div>
              <EditForm
                fRef={bornCategoryFormRef}
                iRef={bornCategoryRef}
                fname="category"
                ftype="text"
                detfull=""
                aIndexName={born?.category}
                onSubmit={(ev) =>
                  runBornEdit(
                    ev,
                    born?.id,
                    bornCategoryFormRef,
                    bornCategoryRef,
                    bornStr
                  )
                }
              />
              <EditForm
                fRef={bornDatesFormRef}
                iRef={bornDatesRef}
                fname="dates"
                ftype="text"
                detfull=""
                aIndexName={born?.dates}
                onSubmit={(ev) =>
                  runBornEdit(
                    ev,
                    born?.id,
                    bornDatesFormRef,
                    bornDatesRef,
                    bornStr
                  )
                }
              />
              <EditForm
                fRef={bornLocationFormRef}
                iRef={bornLocationRef}
                fname="location"
                ftype="text"
                detfull=""
                aIndexName={born?.location}
                onSubmit={(ev) =>
                  runBornEdit(
                    ev,
                    born?.id,
                    bornLocationFormRef,
                    bornLocationRef,
                    bornStr
                  )
                }
              />
              <EditForm
                fRef={bornOrderFormRef}
                iRef={bornOrderRef}
                fname="order"
                ftype="text"
                detfull=""
                aIndexName={born?.order}
                onSubmit={(ev) =>
                  runBornEdit(
                    ev,
                    born?.id,
                    bornOrderFormRef,
                    bornOrderRef,
                    bornStr
                  )
                }
              />
              <EditForm
                fRef={bornSectionFormRef}
                iRef={bornSectionRef}
                fname="section"
                ftype="text"
                detfull=""
                aIndexName={born?.section}
                onSubmit={(ev) =>
                  runBornEdit(
                    ev,
                    born?.id,
                    bornSectionFormRef,
                    bornSectionRef,
                    bornStr
                  )
                }
              />
              <EditForm
                fRef={bornSubtextFormRef}
                iRef={bornSubtextRef}
                fname="subtext"
                ftype="text"
                detfull=""
                aIndexName={born?.subtext}
                onSubmit={(ev) =>
                  runBornEdit(
                    ev,
                    born?.id,
                    bornSubtextFormRef,
                    bornSubtextRef,
                    bornStr
                  )
                }
              />
              <EditForm
                fRef={bornTextFormRef}
                tRef={bornTextRef}
                fname="text"
                ftype="text"
                detfull=""
                aIndexName={born?.text}
                onSubmit={(ev) =>
                  runBornEdit(
                    ev,
                    born?.id,
                    bornTextFormRef,
                    bornTextRef,
                    bornStr
                  )
                }
              />
              <EditForm
                fRef={bornYearFormRef}
                iRef={bornYearRef}
                fname="year"
                ftype="text"
                detfull=""
                aIndexName={born?.year}
                onSubmit={(ev) =>
                  runBornEdit(
                    ev,
                    born?.id,
                    bornYearFormRef,
                    bornYearRef,
                    bornStr
                  )
                }
              />
              <div style={{ display: "flex", alignItems: "baseline" }}>
                <p>ID: {born?.id}</p>
              </div>

              <button className="btn" onClick={() => setBioborn(false)}>
                Close Born
              </button>
            </div>
          </div>
        </div>
      ) : (
        <button className="btn" onClick={() => setBioborn(true)}>
          Edit Born
        </button>
      )}
    </div>
  );
};

export default BioBornForm;
