import { useRef, useState, useEffect } from "react";
import EditForm from "../template/EditForm";
import AddRepCityForm from "./add/AddRepCityForm";
import { db } from "../../fbserve";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  collection,
  getDocs,
  setDoc,
  updateDoc,
  doc,
  deleteDoc,
  orderBy,
  query,
} from "firebase/firestore";

const CitiesForm = () => {
  let dataRef: any;
  const cityStr = "pages/representation/city";
  const techStepsRef = query(collection(db, cityStr));
  const qcities = query(techStepsRef, orderBy("order", "asc"));

  const cityUploadFormRef = useRef<HTMLFormElement>(null);
  const cityUploadRef = useRef<HTMLInputElement>(null);
  const cityAddressFormRef = useRef<HTMLFormElement>(null);
  const cityAddressRef = useRef<HTMLInputElement>(null);
  const cityColourFormRef = useRef<HTMLFormElement>(null);
  const cityColourRef = useRef<HTMLInputElement>(null);
  const cityFormatsFormRef = useRef<HTMLFormElement>(null);
  const cityFormatsRef = useRef<HTMLSelectElement>(null);
  const cityGalleryFormRef = useRef<HTMLFormElement>(null);
  const cityGalleryRef = useRef<HTMLInputElement>(null);
  const cityHeightpxFormRef = useRef<HTMLFormElement>(null);
  const cityHeightpxRef = useRef<HTMLInputElement>(null);
  const cityHoursFormRef = useRef<HTMLFormElement>(null);
  const cityHoursRef = useRef<HTMLInputElement>(null);
  const cityImgaltFormRef = useRef<HTMLFormElement>(null);
  const cityImgaltRef = useRef<HTMLInputElement>(null);
  const cityImgqualityFormRef = useRef<HTMLFormElement>(null);
  const cityImgqualityRef = useRef<HTMLSelectElement>(null);
  const cityImgsrcFormRef = useRef<HTMLFormElement>(null);
  const cityImgsrcRef = useRef<HTMLInputElement>(null);
  const cityLocationFormRef = useRef<HTMLFormElement>(null);
  const cityLocationRef = useRef<HTMLInputElement>(null);
  const cityMapFormRef = useRef<HTMLFormElement>(null);
  const cityMapRef = useRef<HTMLInputElement>(null);
  const cityOrderFormRef = useRef<HTMLFormElement>(null);
  const cityOrderRef = useRef<HTMLInputElement>(null);
  const cityPhoneFormRef = useRef<HTMLFormElement>(null);
  const cityPhoneRef = useRef<HTMLInputElement>(null);
  const cityUrlFormRef = useRef<HTMLFormElement>(null);
  const cityUrlRef = useRef<HTMLInputElement>(null);
  const cityWidthpxFormRef = useRef<HTMLFormElement>(null);
  const cityWidthpxRef = useRef<HTMLInputElement>(null);
  const cityWidththumbFormRef = useRef<HTMLFormElement>(null);
  const cityWidththumbRef = useRef<HTMLInputElement>(null);
  const addRepCityFormRef = useRef<HTMLFormElement>(null);
  const addRepGalleryRef = useRef<HTMLInputElement>(null);
  const addRepLocationRef = useRef<HTMLInputElement>(null);
  const addRepCityOrderRef = useRef<HTMLInputElement>(null);

  const [cities, setCities] = useState([] as Array<any> | null);
  const [editCity, setEditCity] = useState(false);
  const [cityIndex, setCityIndex] = useState(0);
  const [redEdited, setRedEdited] = useState(true);
  const [deleted, setDeleted] = useState(false);

  const getRepCities = async () => {
    const repcities = [] as Array<any>;
    const querySnapshot = await getDocs(qcities);
    querySnapshot.forEach((doc: any) => {
      repcities?.push({ ...doc.data(), id: doc.id });
    }) as unknown as any[];
    setCities(repcities);
  };

  useEffect(() => {
    getRepCities();
  });

  useEffect(() => {
    setRedEdited(true);
  }, [redEdited]);

  const procEditCity = (i: number) => {
    const nextState = i;
    setEditCity(true);
    setCityIndex(nextState);
  };

  const addRepCity = async (ev: any) => {
    ev.preventDefault();
    const pgId: any = addRepLocationRef.current?.value.toLowerCase();
    const iOrder = addRepCityOrderRef.current?.value as string;
    await setDoc(doc(db, cityStr, pgId), {
      colour: "hsl(50, 50%, 50%)",
      formats: ["avif", "webp"],
      gallery: addRepGalleryRef.current?.value,
      heightpx: 152,
      imgquality: "medium",
      order: parseInt(iOrder),
      location: addRepLocationRef.current?.value,
      widthpx: 152,
      widththumb: 152,
    })
      .then(() => {
        addRepCityFormRef.current!.reset();
        getRepCities();
      })
      .catch((error) => console.log(error.message));
  };
  const runCityEdit = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.value;
    const inptName = refInpt.current?.name;
    const inptForm = refForm.current;
    if (!inptVal) {
      alert("Did You Forget To Insert A Value?");
      return;
    }
    if (
      inptName === "order" ||
      inptName === "heightpx" ||
      inptName === "widthpx" ||
      inptName === "widththumb"
    ) {
      inptVal = parseInt(inptVal);
    }
    if (inptName.includes("formats")) {
      const opts = inptForm.children[1].selectedOptions!;
      const catOptions = [];
      for (const option of opts) {
        if (option.selected) {
          catOptions.push(option.value);
        }
      }
      inptVal = catOptions;
    }
    dataRef = doc(db, dataStr, id);
    await updateDoc(dataRef, { [`${inptName}`]: inptVal });
    setRedEdited(true);
    inptForm.className = `${redEdited ? "editform red" : "editform"}`;
    inptForm.reset();
    getRepCities();
  };

  const storage = getStorage();
  const urlArr: Array<any> = [];
  const uploadImg = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    const inptVal = refInpt.current?.files;
    const inptName = refInpt.current?.name;
    const inptForm = refForm.current;
    if (inptVal.length < 1 && inptVal.length > 1) {
      alert("Please Select Only One Image File");
      setRedEdited(false);
      inptForm.className = `${redEdited ? "editform red" : "editform"}`;
      inptForm!.reset();
      return;
    } else if (inptVal.length === 1) {
      for (let i: number = 0; i < inptVal!.length; i++) {
        const artimagesRef = ref(storage, `art/${inptVal![i].name}`);
        await uploadBytes(artimagesRef, inptVal![i]);
        await getDownloadURL(artimagesRef)
          .then((url) => {
            urlArr.push(url);
          })
          .catch((error: any) => {
            console.log("Error Getting Img URLs!", error.message);
          });
      }
      dataRef = doc(db, dataStr, id);
      await updateDoc(dataRef, { [`${inptName}`]: urlArr[0].toString() });
      setRedEdited(true);
      inptForm.className = `${redEdited ? "editform red" : "editform"}`;
      inptForm!.reset();
      getRepCities();
    }
  };

  const delPageSec = async (ev: any, id: string, deldir: string) => {
    ev.preventDefault();
    const confirmDel = window.confirm(
      `This cannot be undone: Are You Sure You Want to Delete this ${cities?.[cityIndex]?.gallery} Entry?`
    );
    if (confirmDel) {
      setDeleted(true);
      setRedEdited(false);
      await deleteDoc(doc(db, deldir, `${id}`));
      const setTime = setTimeout(() => {
        setDeleted(false);
        setEditCity(false);
        getRepCities();
      }, 3300);
      return () => {
        clearTimeout(setTime);
      };
    }
  };

  return (
    <div className="editdiv">
      {editCity ? (
        <div>
          <h4 style={{ marginTop: "0.5rem", marginBottom: "0" }}>
            {deleted ? (
              <span style={{ color: "red" }}>Deleted!</span>
            ) : (
              "Editing:"
            )}{" "}
            {cities?.[cityIndex]?.gallery}
          </h4>
          <div>
            <EditForm
              fRef={cityUploadFormRef}
              iRef={cityUploadRef}
              fname="imgsrc"
              ftype="file"
              detfull=""
              aIndexName={cities?.[cityIndex]?.imgsrc}
              onSubmit={(ev) =>
                uploadImg(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityUploadFormRef,
                  cityUploadRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityAddressFormRef}
              iRef={cityAddressRef}
              fname="address"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.address}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityAddressFormRef,
                  cityAddressRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityColourFormRef}
              iRef={cityColourRef}
              fname="colour"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.colour}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityColourFormRef,
                  cityColourRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityFormatsFormRef}
              sRef={cityFormatsRef}
              fname="formats"
              iselect={true}
              detfull=""
              isize={2}
              ioptions={["avif", "webp", "png", "jpg", "gif", "svg"]}
              aIndexName={cities[cityIndex].formats!}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityFormatsFormRef,
                  cityFormatsRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityGalleryFormRef}
              iRef={cityGalleryRef}
              fname="gallery"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.gallery}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityGalleryFormRef,
                  cityGalleryRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityHeightpxFormRef}
              iRef={cityHeightpxRef}
              fname="heightpx"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.heightpx}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityHeightpxFormRef,
                  cityHeightpxRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityHoursFormRef}
              iRef={cityHoursRef}
              fname="hours"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.hours}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityHoursFormRef,
                  cityHoursRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityImgaltFormRef}
              iRef={cityImgaltRef}
              fname="imgalt"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.imgalt}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityImgaltFormRef,
                  cityImgaltRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityImgqualityFormRef}
              sRef={cityImgqualityRef}
              fname="imgquality"
              detfull=""
              iselect={true}
              isize={1}
              ioptions={["max", "high", "mid", "low"]}
              aIndexName={cities?.[cityIndex]?.imgquality}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityImgqualityFormRef,
                  cityImgqualityRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityImgsrcFormRef}
              iRef={cityImgsrcRef}
              fname="imgsrc"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.imgsrc}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityImgsrcFormRef,
                  cityImgsrcRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityLocationFormRef}
              iRef={cityLocationRef}
              fname="location"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.location}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityLocationFormRef,
                  cityLocationRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityMapFormRef}
              iRef={cityMapRef}
              fname="map"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.map}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityMapFormRef,
                  cityMapRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityOrderFormRef}
              iRef={cityOrderRef}
              fname="order"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.order}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityOrderFormRef,
                  cityOrderRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityPhoneFormRef}
              iRef={cityPhoneRef}
              fname="phone"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.phone}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityPhoneFormRef,
                  cityPhoneRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityUrlFormRef}
              iRef={cityUrlRef}
              fname="url"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.url}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityUrlFormRef,
                  cityUrlRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityWidthpxFormRef}
              iRef={cityWidthpxRef}
              fname="widthpx"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.widthpx}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityWidthpxFormRef,
                  cityWidthpxRef,
                  cityStr
                )
              }
            />
            <EditForm
              fRef={cityWidththumbFormRef}
              iRef={cityWidththumbRef}
              fname="widththumb"
              ftype="text"
              detfull=""
              aIndexName={cities?.[cityIndex]?.widththumb}
              onSubmit={(ev) =>
                runCityEdit(
                  ev,
                  cities?.[cityIndex]?.id,
                  cityWidththumbFormRef,
                  cityWidththumbRef,
                  cityStr
                )
              }
            />
            <div style={{ display: "flex", alignItems: "baseline" }}>
              <p>ID: {cities?.[cityIndex]?.id}</p>
              <button
                className="btn"
                onClick={(ev) =>
                  delPageSec(ev, cities?.[cityIndex]?.id, cityStr)
                }
              >
                Delete
              </button>
            </div>
            <button className="btn" onClick={() => setEditCity(false)}>
              Close
            </button>
          </div>
        </div>
      ) : null}
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          paddingBottom: "1rem",
          width: "100%",
        }}
      >
        <div style={{ width: "100%" }}>
          <h4>Cities:</h4>
        </div>
        {cities?.map((city: any, i: number) => (
          <button
            key={city.location}
            style={{ margin: "0.36rem" }}
            className="btn"
            onClick={() => {
              procEditCity(i);
            }}
          >
            {city.location} {city.order}
          </button>
        ))}
      </div>
      <div>
        <AddRepCityForm
          onSubmit={(ev) => {
            addRepCity(ev);
          }}
          fRef={addRepCityFormRef}
          iORef={addRepCityOrderRef}
          iLRef={addRepLocationRef}
          iGRef={addRepGalleryRef}
        />
      </div>
    </div>
  );
};

export default CitiesForm;
