import { useEffect, useRef, useState } from "react";
import { collection, addDoc } from "firebase/firestore";
import { db } from "../../fbserve";

// ###### GITHUB TRIGGER COMPONENT ####
const Trigger = () => {
  const deploysRef = collection(db, "deploys");

  let text: string;
  const [viewform, setViewForm] = useState(false);
  const [gitmessage, setGitMessage] = useState("");
  const [datetime, setDateTime] = useState("");
  const [done, setDone] = useState(false);

  const deployFormRef = useRef<HTMLFormElement | null>(null);
  const dateRef = useRef<HTMLInputElement | null>(null);
  const gitmessageRef = useRef<HTMLInputElement | null>(null);

  const getDateTime = () => {
    setDateTime(new Date().toString());
    // console.log(datetime);
  };

  useEffect(() => {
    getDateTime();
  });

  const doneEdit = () => {
    getDateTime();
    setViewForm(true);
  };

  const copyText = async () => {
    text = `Date: ${datetime} Message: ${gitmessageRef.current?.value}`;
    try {
      await navigator.clipboard.writeText(text);
      console.log("Content copied to clipboard");
    } catch (err) {
      console.error("Failed to copy: ", err);
    }
  };

  const addDeploysDoc = async (ev: any) => {
    ev.preventDefault();
    const messStg: any = gitmessageRef.current?.value;
    setGitMessage(messStg);
    copyText();
    await addDoc(deploysRef, {
      date: datetime,
      message: gitmessageRef.current?.value,
    })
      .then(() => {
        setDone(true);
      })
      .then(() => deployFormRef?.current?.reset())
      .catch((error: any) => {
        const errorMessage = error?.message;
        alert(errorMessage);
      });
  };

  return (
    <div
      style={{
        borderTop: "1px solid white",
        paddingTop: "1rem",
        display: "block",
      }}
    >
      <button className="btn" onClick={() => doneEdit()}>
        Done Editing?
      </button>
      {viewform ? (
        <form
          className=""
          ref={deployFormRef}
          autoComplete="off"
          onSubmit={(ev) => addDeploysDoc(ev)}
          name="add"
        >
          <fieldset className="formfield">
            <label htmlFor="title">date: </label>
            <input
              id="date"
              ref={dateRef}
              type="text"
              name="date"
              value={datetime}
              onChange={() => setDateTime(datetime)}
              required
            />
          </fieldset>
          <fieldset className="formfield">
            <label htmlFor="addkeyid">message: </label>
            <input
              id="message"
              ref={gitmessageRef}
              type="text"
              name="message"
              required
            />
          </fieldset>

          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginBottom: "1rem",
            }}
          >
            <button type="submit" className="btn valuebtn">
              Continue...
            </button>
          </div>
        </form>
      ) : null}

      {done ? (
        <div>
          <h4 style={{ margin: 0 }}>
            Text Copied - Paste this into the Readme.md file and Deploy Your
            Website
          </h4>
          <p>
            Date: {datetime} Message: {gitmessage}
          </p>
          <a
            className="btn"
            href="https://github.com/linoxyn/jhartist/edit/main/README.md"
            target="_blank"
            rel="nofollow me noopener noreferrer"
            onClick={() => {
              setDone(false);
              setViewForm(false);
              //   setDateTime();
            }}
          >
            Go to GitHub
          </a>
        </div>
      ) : null}
    </div>
  );
};

export default Trigger;
