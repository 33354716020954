import { useRef, useState, useEffect } from "react";
import EditForm from "../template/EditForm";
import AddTeachForm from "./add/AddTeachForm";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  deleteDoc,
  orderBy,
  query,
} from "firebase/firestore";
import { db } from "../../fbserve";

const BioTeachForm = () => {
  let dataRef: any;
  const teachStr = "pages/biography/teaching";
  const teachingRef = query(collection(db, teachStr));
  const qteaching = query(teachingRef, orderBy("order", "desc"));
  // add Technique Ents Refs
  const teachCategoryFormRef = useRef<HTMLFormElement>(null);
  const teachCategoryRef = useRef<HTMLInputElement>(null);
  const teachDatesFormRef = useRef<HTMLFormElement>(null);
  const teachDatesRef = useRef<HTMLInputElement>(null);
  const teachLocationFormRef = useRef<HTMLFormElement>(null);
  const teachLocationRef = useRef<HTMLInputElement>(null);
  const teachOrderFormRef = useRef<HTMLFormElement>(null);
  const teachOrderRef = useRef<HTMLInputElement>(null);
  const teachSectionFormRef = useRef<HTMLFormElement>(null);
  const teachSectionRef = useRef<HTMLInputElement>(null);
  const teachSubtextFormRef = useRef<HTMLFormElement>(null);
  const teachSubtextRef = useRef<HTMLInputElement>(null);
  const teachTextFormRef = useRef<HTMLFormElement>(null);
  const teachTextRef = useRef<HTMLTextAreaElement>(null);
  const teachYearFormRef = useRef<HTMLFormElement>(null);
  const teachYearRef = useRef<HTMLInputElement>(null);
  const addTeachFormRef = useRef<HTMLFormElement>(null);
  const addTeachOrderRef = useRef<HTMLInputElement>(null);
  const addTeachTextRef = useRef<HTMLTextAreaElement>(null);

  const [teaching, setTeaching] = useState([] as Array<any> | null);
  const [editTeach, setEditTeach] = useState(false);
  const [bioTeach, setBioTeach] = useState(false);
  const [teachIndex, setTeachIndex] = useState(0);
  const [redEdited, setRedEdited] = useState(true);
  const [deleted, setDeleted] = useState(false);

  const getTeaching = async () => {
    const teachEnts = [] as Array<any>;
    const querySnapshot = await getDocs(qteaching);
    querySnapshot.forEach((doc: any) => {
      teachEnts?.push({ ...doc.data(), id: doc.id });
    }) as unknown as any[];
    setTeaching(teachEnts);
  };

  useEffect(() => {
    getTeaching();
  });

  useEffect(() => {
    setRedEdited(true);
  }, [redEdited]);

  const procEditTeach = (i: number) => {
    const nextState = i;
    setEditTeach(true);
    setTeachIndex(nextState);
  };

  const addTechStep = async (ev: any) => {
    ev.preventDefault();

    const iOrder = addTeachOrderRef.current?.value as string;
    await addDoc(collection(db, teachStr), {
      category: "bio",
      order: parseInt(iOrder),
      section: "Teaching",
      subtext: "",
      text: addTeachTextRef.current?.value,
    })
      .then(() => {
        addTeachFormRef.current!.reset();
        getTeaching();
      })
      .catch((error) => console.log(error.message));
  };

  const delPageSec = async (ev: any, id: string, deldir: string) => {
    ev.preventDefault();
    const confirmDel = window.confirm(
      `This cannot be undone: Are You Sure You Want to Delete this ${teaching?.[teachIndex]?.text} Entry?`
    );
    if (confirmDel) {
      setDeleted(true);
      setRedEdited(false);
      await deleteDoc(doc(db, deldir, `${id}`));
      const setTime = setTimeout(() => {
        setDeleted(false);
        setEditTeach(false);
        getTeaching();
      }, 3300);
      return () => {
        clearTimeout(setTime);
      };
    }
  };

  const runTeachEdit = async (
    ev: any,
    id: string,
    refForm: any,
    refInpt: any,
    dataStr: string
  ) => {
    ev.preventDefault();
    let inptVal = refInpt.current?.value;
    const inptName = refInpt.current?.name;
    const inptForm = refForm.current;
    if (!inptVal) {
      alert("Did You Forget To Insert A Value?");
      return;
    }
    if (inptName === "order") {
      inptVal = parseInt(inptVal);
    }
    if (inptName.includes("formats")) {
      const opts = inptForm.children[1].selectedOptions!;
      const catOptions = [];
      for (const option of opts) {
        if (option.selected) {
          catOptions.push(option.value);
        }
      }
      inptVal = catOptions;
    }
    dataRef = doc(db, dataStr, id);
    await updateDoc(dataRef, { [`${inptName}`]: inptVal });
    setRedEdited(true);
    inptForm.className = `${redEdited ? "editform red" : "editform"}`;
    inptForm.reset();
    getTeaching();
  };

  return (
    <div>
      {bioTeach === true ? (
        <div className="editdiv">
          {editTeach ? (
            <div>
              <h4 style={{ marginTop: "0.5rem", marginBottom: "0" }}>
                {deleted ? (
                  <span style={{ color: "red" }}>Deleted!</span>
                ) : (
                  "Editing:"
                )}{" "}
                Biography Teaching - {teaching?.[teachIndex]?.text}
              </h4>
              <div>
                <EditForm
                  fRef={teachCategoryFormRef}
                  iRef={teachCategoryRef}
                  fname="category"
                  ftype="text"
                  detfull=""
                  aIndexName={teaching?.[teachIndex]?.category}
                  onSubmit={(ev) =>
                    runTeachEdit(
                      ev,
                      teaching?.[teachIndex]?.id,
                      teachCategoryFormRef,
                      teachCategoryRef,
                      teachStr
                    )
                  }
                />
                <EditForm
                  fRef={teachDatesFormRef}
                  iRef={teachDatesRef}
                  fname="dates"
                  ftype="text"
                  detfull=""
                  aIndexName={teaching?.[teachIndex]?.dates}
                  onSubmit={(ev) =>
                    runTeachEdit(
                      ev,
                      teaching?.[teachIndex]?.id,
                      teachDatesFormRef,
                      teachDatesRef,
                      teachStr
                    )
                  }
                />
                <EditForm
                  fRef={teachLocationFormRef}
                  iRef={teachLocationRef}
                  fname="location"
                  ftype="text"
                  detfull=""
                  aIndexName={teaching?.[teachIndex]?.location}
                  onSubmit={(ev) =>
                    runTeachEdit(
                      ev,
                      teaching?.[teachIndex]?.id,
                      teachLocationFormRef,
                      teachLocationRef,
                      teachStr
                    )
                  }
                />
                <EditForm
                  fRef={teachOrderFormRef}
                  iRef={teachOrderRef}
                  fname="order"
                  ftype="text"
                  detfull=""
                  aIndexName={teaching?.[teachIndex]?.order}
                  onSubmit={(ev) =>
                    runTeachEdit(
                      ev,
                      teaching?.[teachIndex]?.id,
                      teachOrderFormRef,
                      teachOrderRef,
                      teachStr
                    )
                  }
                />
                <EditForm
                  fRef={teachSectionFormRef}
                  iRef={teachSectionRef}
                  fname="section"
                  ftype="text"
                  detfull=""
                  aIndexName={teaching?.[teachIndex]?.section}
                  onSubmit={(ev) =>
                    runTeachEdit(
                      ev,
                      teaching?.[teachIndex]?.id,
                      teachSectionFormRef,
                      teachSectionRef,
                      teachStr
                    )
                  }
                />
                <EditForm
                  fRef={teachSubtextFormRef}
                  iRef={teachSubtextRef}
                  fname="subtext"
                  ftype="text"
                  detfull=""
                  aIndexName={teaching?.[teachIndex]?.subtext}
                  onSubmit={(ev) =>
                    runTeachEdit(
                      ev,
                      teaching?.[teachIndex]?.id,
                      teachSubtextFormRef,
                      teachSubtextRef,
                      teachStr
                    )
                  }
                />
                <EditForm
                  fRef={teachTextFormRef}
                  tRef={teachTextRef}
                  fname="text"
                  ftype="text"
                  detfull=""
                  aIndexName={teaching?.[teachIndex]?.text}
                  onSubmit={(ev) =>
                    runTeachEdit(
                      ev,
                      teaching?.[teachIndex]?.id,
                      teachTextFormRef,
                      teachTextRef,
                      teachStr
                    )
                  }
                />
                <EditForm
                  fRef={teachYearFormRef}
                  iRef={teachYearRef}
                  fname="year"
                  ftype="text"
                  detfull=""
                  aIndexName={teaching?.[teachIndex]?.year}
                  onSubmit={(ev) =>
                    runTeachEdit(
                      ev,
                      teaching?.[teachIndex]?.id,
                      teachYearFormRef,
                      teachYearRef,
                      teachStr
                    )
                  }
                />
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <p>ID: {teaching?.[teachIndex]?.id}</p>
                  <button
                    className="btn"
                    onClick={(ev) =>
                      delPageSec(ev, teaching?.[teachIndex]?.id, teachStr)
                    }
                  >
                    Delete
                  </button>
                </div>
                <button className="btn" onClick={() => setEditTeach(false)}>
                  Close
                </button>
              </div>
            </div>
          ) : null}
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              paddingBottom: "1rem",
              width: "100%",
            }}
          >
            <div style={{ width: "100%" }}>
              <h4>Teaching:</h4>
            </div>
            {teaching?.map((teach: any, i: number) => (
              <button
                key={teach.order}
                style={{ margin: "0.36rem" }}
                className="btn"
                onClick={() => {
                  procEditTeach(i);
                }}
              >
                {teach.text} {teach.order}
              </button>
            ))}
          </div>
          <h4>Add Teaching:</h4>
          <div>
            <AddTeachForm
              onSubmit={(ev) => {
                addTechStep(ev);
              }}
              fRef={addTeachFormRef}
              iTRef={addTeachTextRef}
              iORef={addTeachOrderRef}
            />
          </div>
          <button className="btn" onClick={() => setBioTeach(false)}>
            Close Teaching
          </button>
        </div>
      ) : (
        <button className="btn" onClick={() => setBioTeach(true)}>
          Edit Teaching
        </button>
      )}
    </div>
  );
};

export default BioTeachForm;
